.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header > h4 {
  color: blanchedalmond;
}

.App-link {
  color: #09d3ac;
}

.select {
  min-width: 300px;
}

.aimage {
  padding: 10px;
  border: 2px solid white;
  margin: 1rem;
}
